import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";

// firebase apps
import { portalsApp } from "@/firebase";
// import "./firebase";
// firebase apps

// vuefire
import { firestorePlugin } from "vuefire";
Vue.use(firestorePlugin);
// vuefire

// helper functions
import "./helpers";
// helper functions

// vuePapaParse (for CSV file uploads)
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

// https://www.npmjs.com/package/vue-toastification#features
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter((t) => t.type === toast.type).length !== 0) return false;
  // Returning false discards the toast
  // You can modify the toast if you want
  return toast;
};
Vue.use(Toast, { filterBeforeCreate });

// NProgress
// https://www.npmjs.com/package/nprogress
// https://github.com/rstacruz/nprogress
// https://github.com/rstacruz/nprogress/issues/187
import "nprogress/nprogress.css";

// https://www.algolia.com/doc/guides/building-search-ui/getting-started/vue/
// https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/query-suggestions/tutorials/building-query-suggestions-ui/vue/
import InstantSearch from "vue-instantsearch";
Vue.use(InstantSearch);

// https://github.com/ankane/vue-chartkick/blob/vue2-docs/README.md
// https://chartkick.com/vue
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
Vue.use(Chartkick.use(Chart));

// https://www.npmjs.com/package/tiptap-vuetify
// https://github.com/iliyaZelenko/tiptap-vuetify#events
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

Vue.config.productionTip = false;

// Import Quill and the Vue wrapper
import VueQuillEditor from "vue-quill-editor";

// Import the Quill Editor styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// Register the Vue wrapper for Quill globally
Vue.use(VueQuillEditor);


let app;

portalsApp.auth().onAuthStateChanged((user) => {
  if (!app) {
    new Vue({
      router,
      store,
      vuetify,
      render: function(h) {
        return h(App);
      },
    }).$mount("#app");
  }
});
